<template>
  <div class="no-data">
    <i class="iconfont icon-kongshuju" />
    No Data
  </div>
</template>

<style lang="less" scoped>
.no-data {
  display: flex;
  font-size: 16px;
  margin-top: 10%;
  font-weight: 500;
  color: var(--dark-3);
  align-items: center;
  padding-bottom: 120px;
  flex-direction: column;
  > .iconfont {
    width: auto;
    height: 88px;
    display: block;
    font-size: 88px;
    line-height: 88px;
    font-weight: normal;
    color: var(--dark-4);
    margin-bottom: 16px;
  }
}
</style>