/*
* @Author: Gang Jiang
* @Date: 2024-11-25 15:25:01
* @Last Modified by: Gang Jiang
* @Last Modified time: 2025-03-12 09:48:01
*/

<template>
  <div class="product" ref="zjdgx">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-shangpin"></use>
      </svg>
    </breadcrumbs>
    <div class="content-wrapper">
      <div class="left-filter">
        <div class="item seperates categories">
          <h3 @click="setCategory">
            <i class="iconfont icon-jiantou-zuo-cu" v-if="filter.categoryId" />All Categories<i :class="'iconfont icon-' +
              (categoryCollapsed ? 'jiahao-cu' : 'henggang-jiacu')
              " @click.stop="collapse('category')"></i>
          </h3>
          <div class="category-tree" v-show="!categoryCollapsed">
            <category-new :filter="filter" ref="categoryTree" @setCategory="setCategory" />
          </div>
        </div>
        <div class="item seperates brands">
          <a-spin :spinning="isSearchingBrands">
            <h3>
              Brands<i :class="'iconfont icon-' +
                (brandCollapsed ? 'jiahao-cu' : 'henggang-jiacu')
                " @click.stop="collapse('brand')"></i>
            </h3>
            <a-input-search allow-clear v-if="!brandCollapsed" @change="getBrandsList" placeholder="Search Brand..."
              v-model:value="brandSearch.brandName" />
            <div class="brands-list" v-if="!brandCollapsed" @scroll="loadMoreBrands">
              <a-checkbox class="brand-item" v-for="(brand, index) in brands" :key="brand.brandName"
                :checked="brand.selected" @change="setFilter('brand', { ...brand, index })">
                {{ brand.brandName }}</a-checkbox>
              <a-spin size="small" class="loading-more" :spinning="isLoadingBrands" />
            </div>
          </a-spin>
        </div>
        <div class="item commission mb10">
          <h3>Commission Min</h3>
          <div class="commission-input">
            <artmisads-input-number min="0" max="100" suffix="%" :step="0.1" class="width100"
              v-model:value="commissionRef" placeholder="Please enter..."
              @blur="(e) => setFilter('commission', e.target.value)"
              @pressEnter="(e) => setFilter('commission', e.target.value)" />
          </div>
        </div>
        <div class="item mb10 price">
          <h3>Price</h3>
          <artmisads-select class="width100" :options="priceOptions" v-model:value="filter.price"
            @select="(id, option) => setFilter('price', option)" />
        </div>
      </div>
      <div class="right-content">
        <div class="top">
          <h2>
            {{ filter.categoryName
            }}<artmisads-select :width="200" class="country" :dropdownMatchSelectWidth="false"
              v-model:value="filter.countryHost" :getPopupContainer="() => $refs.zjdgx"
              :default-value="CURRENCY_MAPPER['US'].host" @change="(value, option) => setFilter('country', option)">
              <a-select-option class="country-select-item" v-for="item in Object.keys(CURRENCY_MAPPER)"
                :key="item.toLowerCase()" :value="CURRENCY_MAPPER[item].host"
                :currency="CURRENCY_MAPPER[item].currency">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="'#icon-guoqi-' + item.toLowerCase()"></use>
                </svg>
                {{ CURRENCY_MAPPER[item].host }}
              </a-select-option>
            </artmisads-select>
          </h2>
          <div class="top-filter">
            <div class="flex-left">
              <top-pagination :pagination="{
                total: productCount,
                size: filter.size,
                current: filter.page,
              }" @pageChange="(page) => setFilter('page', page)" />
              <a-input-search allowClear class="search" placeholder="search..." v-model:value="filter.keywords"
                @search="(v) => setFilter('keywords', v)" />
            </div>
            <div class="flex-right">
              <div class="deals-filter">
                <span>Deals:</span>
                <artemisads-segmented v-model:value="filter.deal" @change="(v) => setFilter('deal', v)"
                  :options="['All', 'Active', 'Upcoming', 'Off']" />
              </div>
              <div class="in-stock">
                <a-checkbox v-model:checked="filter.inStockOnly" @change="setFilter">In Stock</a-checkbox>
              </div>
              <artmisads-select class="with-sort-icon" v-model:value="filter.sort" style="width: 160px"
                @change="(option) => setFilter('sort', option)">
                <a-select-option value="%2BbestSellers">Best Sellers</a-select-option>
                <a-select-option value="-newlyAdded">Newly Added</a-select-option>
                <a-select-option value="-commission">Commission</a-select-option>
                <a-select-option value="-price">Price</a-select-option>
              </artmisads-select>
            </div>
          </div>
          <div class="filter-list">
            <a-tag :closable="true" :key="brand.brandName" @close="setFilter('brand', { ...brand, remove: true })"
              v-for="brand in filter.selectedBrands">{{ brand.brandName }}</a-tag>
            <a-tag :closable="true" v-if="filter.commission" @close="setFilter('commission', null)">Commission Min {{
              filter.commission }}%</a-tag>
            <a-tag :closable="true" v-if="filter.inStockOnly" @close="setFilter('inStockOnly', false)">In Stock</a-tag>
            <a-tag :closable="true" @close="setFilter('price', { label: 'All' })"
              v-if="filter.minPrice || filter.maxPrice">{{ filter.price }}</a-tag>
            <a-tag class="reset-filter" v-if="filterCount" @click="resetFilter">Reset <em>{{ filterCount }}</em>
              Filters</a-tag>
          </div>
        </div>
        <a-spin :spinning="isLoadingList">
          <div :class="'list' + (productCount <= filter.size ? ' single-page' : '')
            ">
            <a class="item" :href="'/publisher/products/' + product.id" target="_blank"
              v-for="(product, index) in productList" :key="product.id">
              <div class="img-wrapper">
                <div class="img-bg">
                  <div v-if="product.combineDeal" class="deal-sign">deal</div>
                  <img class="logo" :src="product.imageUrl" />
                </div>
              </div>
              <p class="price">
                <span v-if="product.combineDeal">
                  <span>{{ product.combineDeal.currencySymbol
                    }}{{ product.combineDeal.finalPrice.toFixed(2) }}</span>
                  <span class="original">{{ product.combineDeal.currencySymbol
                    }}{{ product.combineDeal.fullPrice.toFixed(2) }}
                  </span>
                </span>
                <span v-else>
                  {{ product.currencySymbol }}{{ product.price }}
                </span>
              </p>
              <p class="priceOff" v-if="product.combineDeal">
                {{ product.combineDeal.priceOff.toFixed(1) }}% off
              </p>
              <p class="title">
                {{ product.title }}
              </p>
              <div class="star">
                <a-rate :value="+product.productStarRating" allow-half disabled />{{ product.productNumRatings }}
              </div>
              <div class="place-bottom">
                <div class="commission">
                  Comm: <i class="ratio">{{ product.commission }}%</i>
                </div>
                <div class="est-payout" v-if="product.estPayout">
                  Est. Payout:
                  <i>{{ product.currencySymbol
                    }}{{ Number(product.estPayout).toFixed(2) }}</i>
                </div>
              </div>
              <span class="get-link" @click.stop="getTrackLink($event, index, product)">Get Link</span>
            </a>
          </div>
          <a-pagination class="pagination" show-less-items @change="setPage" :total="productCount"
            :pageSize="filter.size" :showSizeChanger="true" v-model:current="filter.page"
            v-if="productCount > filter.size" :pageSizeOptions="['10', '20', '30', '50', '100']"
            @showSizeChange="(c, size) => setFilter('size', size)">
            <template #buildOptionText="props">
              <span>{{ props.value }} per page</span>
            </template>
            <template #itemRender="{ type, originalElement }">
              <a v-if="type === 'prev'">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-jiantou-zuo-cu"></use>
                </svg>
              </a>
              <a v-else-if="type === 'next'">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-jiantou-you-cu"></use>
                </svg>
              </a>
              <component :is="originalElement" v-else></component>
            </template>
          </a-pagination>
          <no-data v-if="productCount == 0" />
        </a-spin>
      </div>
      <!-- 两种场景 -->
      <!-- 从未生成过Track Link的需要传productId -->
      <!-- 生成过Track Link的则需要传trackLinkId -->
      <LinkToolModal :productId="linkModalParams.productId" :trackLinkId="linkModalParams.openTrackLinkId"
        :countryCode="linkModalParams.countryCode" v-model:open="linkModalParams.open" @callback="onSaveCallback" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import NoData from '~/components/NoData.vue'
import { PRICE } from '../../../contants/publisher'
import { publisherApi } from '../../../server/index'
import breadcrumbs from '../../seller/breadcrumbs.vue'
import CategoryNew from '~/components/CategoryNew.vue'
import TopPagination from '~/components/TopPagination.vue'
import { CURRENCY_MAPPER } from '../../../contants/common'
import LinkToolModal from './detail/linkToolModal.vue'

const breadcrumbsData = {
  color: '#4682FA',
  hasTitleIcon: true,
  title: 'Products Catalogue',
  des: 'Find the products you want to promote!',
  links: [
    {
      name: 'Home',
      path: '/publisher/products',
    },
    {
      name: 'Products',
      path: '/publisher/products',
    },
  ],
}
const commissionRef = ref(null)
let [countryCode, categoryId, categoryLevel] = (
  localStorage.getItem('previewCategory') || ''
).split('_')

if (!countryCode && localStorage.getItem('selectedSite')) {
  countryCode = localStorage.getItem('selectedSite')
}

const filter = ref({
  page: 1,
  size: 20,
  price: 'All',
  deal: 'Off',
  keywords: '',
  currency: '$',
  minPrice: null,
  maxPrice: null,
  keywords: null,
  commission: null,
  countryCode: countryCode || 'US',
  inStockOnly: false,
  selectedBrands: [],
  categoryName: 'All',
  sort: '%2BbestSellers',
  countryHost: CURRENCY_MAPPER[countryCode || 'US'].host,
  categoryLevel: categoryLevel || null,
  categoryId: categoryId || null,
})
const filterCount = ref(0)
const productCount = ref(0)
const brands = ref([])
const productList = ref([])
const categoryTree = ref(null)
const isLoadingList = ref(false)
const brandCollapsed = ref(false)
const isLoadingBrands = ref(false)
const isSearchingBrands = ref(false)
const categoryCollapsed = ref(false)
const priceOptions = ref(null)
const brandSearch = ref({
  page: 1,
  size: 10,
  total: 0,
  brandName: '',
  inStockOnly: false,
})
const linkModalParams = ref({
  open: false,
  productId: null,
  trackLinkId: null,
  productIndex: null,
  countryCode: filter.value.countryCode,
})

function getBrandsList(event) {
  const keywords = event ? event.target.value : undefined

  if (keywords != undefined) {
    brandSearch.value.page = 1
    isSearchingBrands.value = true
    brandSearch.value.brandName = keywords
  } else {
    isLoadingBrands.value = true
  }

  publisherApi
    .getBrandsList({
      ...brandSearch.value,
      countryCode: filter.value.countryCode,
    })
    .then((res) => {
      const result = res.data.map((item) => {
        return {
          ...item,
          selected: false,
        }
      })

      if (keywords != undefined) {
        isSearchingBrands.value = false
      } else {
        isLoadingBrands.value = false
      }

      if (brandSearch.value.page === 1) {
        brandSearch.value.total = res.total

        if (keywords != undefined) {
          const filterSelectedBrands = filter.value.selectedBrands.filter(
            (brand) =>
              brand.brandName.toLowerCase().indexOf(keywords.toLowerCase()) !=
              -1
          )

          brands.value = [
            ...filterSelectedBrands,
            ...result.filter((item) => {
              for (const s of filterSelectedBrands) {
                if (s.brandName === item.brandName) {
                  return false
                }
              }

              return true
            }),
          ]
        } else {
          brands.value = result
        }
      } else {
        brands.value = [...brands.value, ...result]
      }
    })
}

function getProductList() {
  const params = { ...filter.value }

  Object.keys(params).forEach((key) => {
    if (
      ['price', 'categoryName', 'countryHost'].indexOf(key) != -1 ||
      ['', null, undefined].indexOf(params[key]) != -1
    ) {
      delete params[key]
    }
  })

  if (params.selectedBrands.length) {
    params.brandNames = params.selectedBrands.map((item) => item.brandName)
  }

  delete params['selectedBrands']

  isLoadingList.value = true
  publisherApi.getProductList(params).then((res) => {
    isLoadingList.value = false
    if (filter.value.page == 1) {
      productCount.value = res.total || 0
    }

    productList.value = res.data
  })
}

getBrandsList()
setPriceCurrency()
getProductList()

function setPriceCurrency() {
  priceOptions.value = PRICE.map((item) => {
    return {
      ...item,
      label: item.label.replace(
        /%s/g,
        CURRENCY_MAPPER[filter.value.countryCode].currency
      ),
      value: item.label,
      minPrice: item.min ? filter.value.currency + item.min : null,
      maxPrice: item.max ? filter.value.currency + item.max : null,
    }
  })
  filter.value.price = filter.value.price.replace(
    /[$|£|€]/g,
    filter.value.currency
  )
}

function setFilter(type, option) {
  if (type == 'page') {
    filter.value.page = option
  } else {
    if (type == 'price') {
      // price
      filter.value.price = option.label
      filter.value.minPrice = option.min || null
      filter.value.maxPrice = option.max || null
      filter.value.page = 1
    } else if (type == 'sort') {
      filter.value.sort = option
      filter.value.page = 1
    } else if (type == 'size') {
      filter.value.size = option
      filter.value.page = 1
    } else if (type == 'country') {
      filter.value.categoryId = null
      filter.value.categoryName = 'All'
      filter.value.categoryLevel = null
      filter.value.currency = option.currency
      filter.value.countryHost = option.value
      filter.value.countryCode = option.key.toUpperCase()
      linkModalParams.value.countryCode = filter.value.countryCode

      getBrandsList()
      setPriceCurrency()
      localStorage.setItem('selectedSite', filter.value.countryCode)
      categoryTree.value.getCategories({
        level: 1,
        categoryId: 0,
        countryChanged: true,
      })
    } else if (type == 'keywords') {
      if (option.type) {
        if (option.type == 'click') {
          // clear event
          filter.value.page = 1
        } else {
          return
        }
      } else {
        filter.value.keywords = option
      }
    } else if (type == 'commission') {
      if (filter.value.commission == option) {
        return
      }
      if (isNaN(parseFloat(option))) {
        filter.value.commission = undefined
        commissionRef.value = undefined
      } else {
        filter.value.commission = Math.floor(option * 10) / 10
      }
    } else if (type == 'inStockOnly') {
      filter.value.inStockOnly = option
      // brandSearch.value = {
      //   ...brandSearch.value,
      //   page: 1,
      //   inStockOnly: option,
      // }
      // getBrandsList()
    } else if (type == 'brand') {
      if (!option.remove) {
        // 取消品牌选中
        if (option.selected) {
          filter.value.selectedBrands = filter.value.selectedBrands.filter(
            (brand) => brand.brandName != option.brandName
          )
          brands.value[option.index].selected = false
        } else {
          // 选中品牌
          filter.value.selectedBrands.push({ ...option, selected: true })
          brands.value[option.index].selected = true
        }
      } else {
        // topFilter取消品牌选中
        filter.value.selectedBrands = filter.value.selectedBrands.filter(
          (item) => item.brandName != option.brandName
        )

        const index = brands.value.findIndex(
          (item) => item.brandName === option.brandName
        )

        brands.value[index].selected = false
      }
    } else if (type == 'category') {
      filter.value = {
        ...filter.value,
        ...option,
      }
    }

    filter.value.page = 1
  }

  getProductList()
}

function setCategory(category) {
  if (category.categoryId) {
    const { categoryId, displayName, level } = category

    filter.value.categoryLevel = level
    filter.value.categoryId = categoryId
    filter.value.categoryName = displayName
  } else {
    filter.value.categoryId = null
    filter.value.categoryLevel = null
    filter.value.categoryName = 'All'
    categoryTree.value.getCategories({ categoryId: 0, level: 1 })
  }

  filter.value.page = 1
  getProductList()
}

function setPage(page) {
  filter.value.page = page
  getProductList()
}

function resetFilter() {
  commissionRef.value = ''
  filter.value.price = 'All'
  filter.value.minPrice = null
  filter.value.maxPrice = null
  filter.value.commission = null
  filter.value.inStockOnly = false
  filter.value.selectedBrands = []
  brands.value = brands.value.map((item) => {
    return {
      ...item,
      selected: false,
    }
  })
  getProductList()
}

function collapse(type) {
  if (type === 'category') {
    categoryCollapsed.value = !categoryCollapsed.value
  } else {
    brandCollapsed.value = !brandCollapsed.value
  }
}

const loadMoreBrands = debounce((e) => {
  const { scrollTop, scrollHeight, clientHeight } = e.target
  if (clientHeight + scrollTop >= scrollHeight - 50) {
    if (
      brandSearch.value.total >
      brandSearch.value.page * brandSearch.value.size
    ) {
      brandSearch.value.page += 1
      getBrandsList()
    }
  }
}, 300)

function getTrackLink(event, index, product) {
  event.preventDefault() // 阻止 <a> 标签的默认跳转行为
  event.stopPropagation() // 确保事件不会冒泡
  linkModalParams.value.open = true
  linkModalParams.value.productIndex = index
  linkModalParams.value.productId = product.id
}
function onSaveCallback() {
  linkModalParams.value.open = false
}

watch(filter.value, (val) => {
  filterCount.value = val.selectedBrands.length
  filterCount.value += val.commission ? 1 : 0
  filterCount.value += val.inStockOnly ? 1 : 0
  filterCount.value += val.minPrice || val.maxPrice ? 1 : 0
})
</script>

<style lang="less" scoped>
@import '../../../assets/less/ellipsis.less';
@import '../../../assets/less/pagination.less';
@import '../../../assets/less/ant-checkbox.less';
@import '../../../assets/less/country-select.less';

@text-color: #6c3df2;

.content-wrapper {
  display: flex;
}

.list {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  &.single-page {
    margin-bottom: 40px;
  }

  .item {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    padding-bottom: 60px;
    text-decoration: none;
    background-color: var(--bg-gray-9);

    &:hover {
      border-color: var(--border-1);
      background-color: var(--bg-gray-6);

      .title {
        color: var(--dark-1);
      }

      .get-link {
        display: block;
      }
    }
  }

  .img-wrapper {
    height: 0;
    width: 100%;
    position: relative;
    text-align: center;
    padding-bottom: 80%;
    overflow: hidden;
  }

  .deal-sign {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    padding: 0px 8px;
    color: #ffffff;
    background: #f25544;
    border-radius: 6px 0px 6px 0px;
    z-index: 1;
  }

  .img-bg {
    margin: 8px 8px 0;
    position: relative;
    border-radius: 6px;
    background: #fff;
    width: calc(100% - 16px);
    padding-bottom: calc(80% - 8px);
  }

  .logo {
    top: 50%;
    left: 50%;
    max-width: 100%;
    position: absolute;
    max-height: calc(100% - 16px);
    transform: translate(-50%, -50%);
  }

  .price {
    display: flex;
    color: #999;
    padding: 0 10px;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    color: #0f0629;
    align-items: center;
    justify-content: space-between;

    >i {
      font-size: 14px;
      color: @text-color;
      font-style: normal;
    }

    >span {
      display: flex;
      align-items: center;
    }
  }

  // 元件
  .original {
    font-size: 13px;
    margin-left: 4px;
    color: var(--dark-3);
    font-weight: normal;
    text-decoration: line-through;
  }

  .title {
    height: 51px;
    padding: 0 10px;
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    margin: 8px 0 3px;
    color: var(--dark-2);
    .multiple-ellipsis(3);
  }

  .priceOff {
    font-size: 13px;
    font-weight: 500;
    color: #f25544;
    padding-left: 10px;
    margin-bottom: -8px;
  }

  .star {
    width: 100%;
    height: 17px;
    display: flex;
    font-size: 12px;
    padding-left: 10px;
    align-items: center;
    color: var(--dark-3);
    letter-spacing: 0.34px;

    :deep(.ant) {
      &-rate {
        margin-top: -4px;
        margin-right: 8px;
        color: rgb(245, 166, 35, 0.8);

        &-star {
          &-zero .ant-rate-star-second {
            color: rgba(0, 0, 0, 0.15);
          }

          &-half {
            .ant-rate-star-second {
              color: rgba(0, 0, 0, 0.15);
            }
          }

          >div {
            font-size: 12px;
          }

          &:not(:last-child) {
            margin-inline-end: 2px;
          }
        }
      }
    }
  }

  .place-bottom {
    bottom: 12px;
    position: absolute;
  }

  .commission,
  .est-payout {
    height: 18px;
    font-size: 13px;
    line-height: 18px;
    padding-left: 10px;
    color: var(--dark-3);

    >i {
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0.3px;
    }
  }

  .commission>i {
    color: @text-color;
  }

  .est-payout {
    margin-top: 2px;

    >i {
      color: var(--yellow-4);
    }
  }

  .get-link {
    left: 8px;
    right: 8px;
    bottom: 14px;
    height: 33px;
    display: none;
    cursor: pointer;
    line-height: 33px;
    position: absolute;
    text-align: center;
    border-radius: 4px;
    color: var(--color-white);
    background: var(--primary);
    box-shadow: 0 1px 0 0 var(--box-shadow-1);
  }
}

.left-filter {
  flex: 0 0 215px;
  margin-right: 20px;

  .item {
    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  .width100 {
    width: 100%;
  }

  .price {
    margin-bottom: 20px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  h3 {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    margin-bottom: 10px;

    >.iconfont {
      width: auto;
      height: auto;
      color: var(--dark-3);

      &.icon-henggang-jiacu,
      &.icon-jiahao-cu {
        flex: 1;
        text-align: right;
      }
    }

    .icon-jiantou-zuo-cu {
      width: 16px;
      height: 16px;
      font-size: 14px;
      line-height: 16px;
      margin-right: 4px;
      color: var(--dark-2);
    }
  }

  .categories>h3 {
    cursor: pointer;
  }

  .category-tree {
    margin-left: 10px;
  }

  .brands-list {
    margin-top: 6px;
    overflow-y: auto;
    max-height: 187px;
  }

  .loading-more {
    display: flex;
    font-size: 12px;
    margin-top: 2px;
    align-items: center;
    justify-content: center;

    .ant-spin-text {
      margin-left: 6px;
    }
  }

  .brand-item {
    width: 200px;
    height: 30px;
    display: flex;
    line-height: 30px;
    font-weight: normal;

    &:hover,
    &.ant-checkbox-wrapper-checked {
      color: @text-color;
    }

    :deep(> span:last-of-type) {
      overflow: hidden;
      white-space: nowrap;
      padding-inline-end: 0;
      text-overflow: ellipsis;
      flex: 0 0 calc(100% - 16px);
    }
  }

  .seperates {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-4);
  }
}

.right-content {
  flex: 1;

  h2 {
    display: flex;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    margin-right: -15px;
    padding-right: 15px;
    padding-bottom: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e5ed;
  }

  :deep(.ant) {
    &-pagination {
      text-align: right;
      margin-bottom: 20px;
      justify-content: right;
    }
  }

  .country {
    margin-top: -4px;
  }
}

.top-filter {
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: space-between;

  .flex-left,
  .flex-right {
    display: flex;
    align-items: center;
  }

  .in-stock {
    margin-right: 8px;
  }

  .search {
    width: 180px;
    margin-left: 8px;
  }

  .deals-filter {
    margin-right: 16px;
    > span {
      margin-right: 8px;
      color: var(--dark-2);
    }
  }
}

.filter-list {
  .ant-tag {
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 32px;
    margin-right: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: var(--bg-gray-8);
  }

  :deep(.ant-segmented-item) {
    min-width: auto;
  }

  :deep(.ant) {
    &-tag-close-icon {
      font-size: 12px;
      margin-left: 9px;
      margin-right: -4px;
    }
  }

  .reset-filter {
    cursor: pointer;
    margin-right: 0;
    background: none;
    color: var(--primary-text);

    >em {
      font-weight: 600;
      font-style: normal;
    }
  }
}
</style>