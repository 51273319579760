<template>
  <div class="categories">
    <div
      :key="level"
      :class="
        'level ' +
        level +
        (`level${current.level}` == level ? ' active' : '') +
        (`level${current.level + 1}` == level ? ' margin-more' : '')
      "
      v-for="level in Object.keys(showCategories)"
    >
      <div
        :class="
          'category' + (category.categoryId == current.id ? ' active' : '')
        "
        v-for="category in showCategories[level]"
        :key="category.categoryId"
        @click="getCategories(category)"
      >
        <i
          class="iconfont icon-jiantou-zuo-cu"
          v-if="category.level < current.level"
        /><span class="category-name">{{ category.displayName }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { publisherApi, cooperatorApi } from '~/server/index'

const emit = defineEmits(['setCategory'])
const { filter, isCooperator = false } = defineProps(['filter', 'isCooperator'])
// 当前高亮分类
const current = ref(null)
// 所以包含子类的分类ID及其子类列表
const childrenCategories = ref({})
// 展示分类列表
const showCategories = ref({})

const [previewCountryCode, previewCategoryId, previewCategoryLevel] = (
  localStorage.getItem('previewCategory') || ''
).split('_')

function getCategories(category) {
  const { categoryId, level, countryChanged = false } = category

  if (current.value?.id === categoryId && !countryChanged) {
    return
  }

  if (countryChanged) {
    showCategories.value = {}
    childrenCategories.value = {}
  }

  if (categoryId != current.value || !countryChanged) {
    current.value = {
      id: categoryId,
      level: level,
    }
  } else {
    if (!countryChanged) {
      // 点击分类是当前高亮分类 不做处理
      return
    }
  }

  if (!countryChanged && childrenCategories.value['c' + categoryId]) {
    if (categoryId) {
      showCategories.value['level' + level] = [category]
      showCategories.value['level' + (level + 1)] =
        childrenCategories.value['c' + categoryId]
    } else {
      // 点击'All Categories', 显示全部一级分类
      showCategories.value['level1'] = childrenCategories.value['c0']
    }

    // 将当前选中分类等级下的所有显示分类删除
    for (let key of Object.keys(showCategories.value)) {
      if (key > 'level' + (level + (categoryId ? 1 : 0))) {
        delete showCategories.value[key]
      }
    }

    if (categoryId) {
      // 请求当前分类数据
      emit('setCategory', category)
    }

    return
  }

  let params = {
    ...filter,
    categoryId,
    categoryLevel: level,
  }

  if (!categoryId) {
    params = { countryCode: filter.countryCode }
  } else {
    if (!countryChanged) {
      // 请求当前分类数据
      emit('setCategory', category)
    }
  }

  Object.keys(params).forEach((key) => {
    if (
      [
        'sort',
        'selectedBrands',
        'price',
        'categoryName',
        'currency',
        'countryHost',
      ].indexOf(key) != -1 ||
      ['', null, undefined].indexOf(params[key]) != -1
    ) {
      delete params[key]
    }
  })

  const api = isCooperator ? cooperatorApi : publisherApi
  
  api.getCategoryList(params).then((res) => {
    if (res.data?.length) {
      childrenCategories.value['c' + categoryId] = res.data

      if (categoryId) {
        // 其他层级分类
        showCategories.value['level' + level] = [category]
        showCategories.value['level' + (level + 1)] = [...res.data]
        // 将当前选中分类等级下的所有显示分类删除
        for (let key of Object.keys(showCategories.value)) {
          if (key > 'level' + (level + (categoryId ? 1 : 0))) {
            delete showCategories.value[key]
          }
        }
      } else {
        // 获取所有一级分类
        showCategories.value['level1'] = [...res.data]
        for (let key of Object.keys(showCategories.value)) {
          if (key > 'level1') {
            delete showCategories.value[key]
          }
        }
      }
    }
  })
}

defineExpose({
  getCategories,
})

function getCategoryTree(id, countryCode) {
  publisherApi.getCategoryTree(id, countryCode).then((res) => {
    if (res.success) {
      res.data.map((item) => {
        showCategories.value[`level${item.level}`] = [item]
      })
      current.value = {
        id: res.data[res.data.length - 1].categoryId,
        level: res.data[res.data.length - 1].level,
      }
    }
  })
}

if (previewCountryCode) {
  getCategoryTree(previewCategoryId, previewCountryCode)
  localStorage.removeItem('previewCategory')
} else {
  getCategories({
    categoryId: filter.categoryId || 0,
    level: filter.categoryLevel || 1,
  })
}
</script>

<style lang="less" scoped>
.category {
  display: flex;
  cursor: pointer;
  user-select: none;
  line-height: 19px;
  align-items: center;
  margin-bottom: 16px;
  &.active {
    color: #6c3df2;
    &:hover .category-name {
      cursor: default;
      text-decoration: none;
    }
  }
  &:hover .category-name {
    text-decoration: underline;
  }
  .icon-jiantou-zuo-cu {
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    color: var(--dark-2);
  }
}
.level1 {
  &.active {
    margin-left: 8px;
  }
}
.level2 {
  margin-left: 10px;
  &.active,
  &.margin-more {
    margin-left: 20px;
  }
}
.level3 {
  margin-left: 20px;
  &.active,
  &.margin-more {
    margin-left: 30px;
  }
}
.level4 {
  margin-left: 30px;
  &.active,
  &.margin-more {
    margin-left: 40px;
  }
}
.level5 {
  margin-left: 40px;
  &.active,
  &.margin-more {
    margin-left: 50px;
  }
}
.level6 {
  margin-left: 50px;
  &.active,
  &.margin-more {
    margin-left: 60px;
  }
}
</style>