<template>
    <artmisads-modal class="link-tool" :width="800" v-model:open="open" :footer="null" :maskClosable="false"
        :bodyStyle="{ padding: '24px 24px 12px' }">
        <template #title>
            <span class="title">Generate a Link</span>
        </template>
        <div>
            <a-spin :spinning="loading">
                <div class="link-scroll-area">
                    <div class="link-textarea">
                        {{ modelRef.shortLinkSelect ? modelRef.shortLink : showTrackLink }}
                    </div>
                </div>
            </a-spin>
            <div class="link-short-link">
                <a-checkbox v-model:checked="modelRef.shortLinkSelect">Short Link</a-checkbox>
            </div>
            <a-collapse ghost class="link-collapse" v-if="false" v-model:activeKey="activeKey">
                <a-collapse-panel key="collapse" :showArrow="false">
                    <template #header>
                        <div class="link-collapse-header">
                            <span>Advanced Options</span>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-jiantou-xia-cu"></use>
                            </svg>
                        </div>
                    </template>
                    <div class="link-advanced-option">
                        <div>
                            <p class="sub-title">
                                <span>Amazon tracking tag:</span>
                            </p>
                            <div class="option-item">
                                <a-radio-group v-model:value="modelRef.amazonTagSelect" class="tracking-tag">
                                    <a-radio value="default">
                                        <span>Use your default Amazon tracking tag</span>
                                    </a-radio>
                                    <a href="/publisher/settings/profile" target="_blank">Set defalut Amazon tracking tag</a>
                                    <a-radio value="specific">
                                        <span>Use Speciﬁc Amazon tracking tag</span>
                                    </a-radio>
                                </a-radio-group>
                                <input class="track-tag-input" :maxlength="15" ref="trackTagInput"
                                    :disabled="modelRef.amazonTagSelect != 'specific'" v-model="modelRef.tag"
                                    @input="onTrackTagInput" />
                            </div>
                        </div>
                        <div style="margin-top: 32px">
                            <p class="sub-title" ref="optionTip">
                                <span>Source Options</span>
                                <a-tooltip :getPopupContainer="() => $refs.optionTip">
                                    <template #title>
                                        A source is a method of classifying your links. For instance, a typical one
                                        could be
                                        a speciﬁc article ID or a domain name. You'll be capable of viewing a reporting
                                        breakdown for every source.
                                    </template>
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-wenhao-tianchong"></use>
                                    </svg>
                                </a-tooltip>
                            </p>
                            <div class="option-item" ref="se">
                                <a-select :disabled="trackLinkId" :getContainer="() => $refs.se" placeholder="All" class="source-select artmisads-select"
                                    v-model:value="modelRef.sourceId" :options="sourceOptions" ref="sourceSelect"
                                    :open="sourceSelectOpen"
                                    @dropdownVisibleChange="visible => sourceSelectOpen = visible"
                                    :dropdownStyle="{ overflow: 'unset' }" @change="onChangeTrackLink">
                                    <template #dropdownRender="{ menuNode: menu }">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 8px 0" />
                                        <Editable :allow-clear="true" @callback="onAddSourceOption"
                                            :style="{ margin: '0 auto' }" v-model:close="sourceSelectOpen">
                                            <template #default="slotProps">
                                                <a href="javascript:;" class="source-edit" @click="slotProps.onClick">
                                                    <svg class="icon" aria-hidden="true"
                                                        style="font-size: 16px; margin-right: 8px;">
                                                        <use xlink:href="#icon-jiahao-cu"></use>
                                                    </svg>
                                                    <span>New Soruce</span>
                                                </a>
                                            </template>
                                        </Editable>
                                    </template>
                                    <template #suffixIcon>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-jiantou-xia-cu"></use>
                                        </svg>
                                    </template>
                                </a-select>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
            <div class="source">
                <p class="sub-title" ref="zjdgx">
                    <span>Source Options</span>
                    <a-tooltip :getPopupContainer="() => $refs.zjdgx">
                        <template #title>
                            A source is a method of classifying your links. For instance, a typical one
                            could be
                            a speciﬁc article ID or a domain name. You'll be capable of viewing a reporting
                            breakdown for every source.
                        </template>
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-wenhao-tianchong"></use>
                        </svg>
                    </a-tooltip>
                </p>
                <div class="option-item" ref="se">
                    <a-select :disabled="trackLinkId" :getPopupContainer="() => $refs.se" placeholder="All" class="source-select artmisads-select"
                        v-model:value="modelRef.sourceId" :options="sourceOptions" ref="sourceSelect"
                        :open="sourceSelectOpen"
                        @dropdownVisibleChange="visible => sourceSelectOpen = visible"
                        :dropdownStyle="{ overflow: 'unset' }" @change="onChangeTrackLink">
                        <template #dropdownRender="{ menuNode: menu }">
                            <v-nodes :vnodes="menu" />
                            <a-divider style="margin: 8px 0" />
                            <Editable :allow-clear="true" @callback="onAddSourceOption"
                                :style="{ margin: '0 auto 4px' }" v-model:close="sourceSelectOpen">
                                <template #default="slotProps">
                                    <a href="javascript:;" class="source-edit" @click="slotProps.onClick">
                                        <svg class="icon" aria-hidden="true"
                                            style="font-size: 16px; margin-right: 8px;">
                                            <use xlink:href="#icon-jiahao-cu"></use>
                                        </svg>
                                        <span>New Soruce</span>
                                    </a>
                                </template>
                            </Editable>
                        </template>
                        <template #suffixIcon>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-jiantou-xia-cu"></use>
                            </svg>
                        </template>
                    </a-select>
                </div>
            </div>
            <div class="link-footer">
                <artmisads-button :disabled="loading" class="save_copy" type="primary" @click.stop="onSaveAndCopy">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-copy2-cu"></use>
                    </svg>
                    Save & Copy
                </artmisads-button>
                <artmisads-button :disabled="loading" class="save" type="primary" @click.stop="onSave">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-baocun-cu"></use>
                    </svg>
                    Save
                </artmisads-button>
            </div>
        </div>
    </artmisads-modal>
</template>

<script setup>
import { reactive, ref, computed, watch, watchEffect, defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import { SaveOutlined, CopyOutlined, QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import Editable from '~/components/Editable.vue'

import { publisherProductApi } from '~/server/index'

const open = defineModel('open')
const { productId, trackLinkId, countryCode } = defineProps({ productId: String, trackLinkId: Number || undefined, countryCode: String })
const emit = defineEmits(['callback'])

const sourceSelectOpen = ref(false)
const modelRef = ref({});
//缓存 source options 下拉数据
const sourceOptions = ref([])
const newSourceName = ref();
const activeKey = ref(['collapse']);
const loading = ref(false);

const VNodes = defineComponent({
    props: {
        vnodes: {
            type: Object,
            required: true,
        },
    },
    render() {
        return this.vnodes;
    },
});

// const onTrackTagInput = e => {
//     modelRef.value.tag = modelRef.value.tag.replace(/[^\w-]/g, '')
// }

const showTrackLink = computed(() => {
    let urlObj = new URLSearchParams(modelRef.value.trackingLink)
    modelRef.value.trackingLink = decodeURIComponent(urlObj.toString())
    return modelRef.value.trackingLink
})

const onChangeTrackLink = async () => {
    let { productId, trackingLink, amazonTagSelect, sourceId, asin } = modelRef.value
    loading.value = true;
    let { success, data, error } = await publisherProductApi.getTrackLinkByParams({ productId, countryCode, trackingLink, amazonTagSelect, sourceId, asin }).catch(e => ({ success: false, error: e.message }))
    if (success) {
        modelRef.value.adGroupId = data.adGroupId;
        modelRef.value.trackingLink = data.trackingLink;
    } else {
        message.error(error)
    }
    loading.value = false;
}

const onAddSourceOption = async value => {
    if (value.trim()) {
        let { success, data, error } = await publisherProductApi.addSourceOption({ name: value.trim() }).catch(e => ({ success: false, error: e.message }))
        if (success) {
            sourceOptions.value.push({ label: data.name, value: data.id })
            modelRef.value.sourceId = data.id;
            modelRef.value.sourceName = data.name;
            sourceSelectOpen.value = false;
            onChangeTrackLink()
        } else {
            message.error(error)
        }
    }
}

const onSave = async () => {
    let { success, data, error } = await publisherProductApi.saveGenerateLink(productId, countryCode, modelRef.value).catch(e => ({ success: false, error: e.message }))
    if (success) {
        message.success('Success!', 1);
        let updateData = {
            amazonTagSelect: modelRef.value.amazonTagSelect,
            trackLinkId: data.id,
            trackingLink: data.trackingLink,
            linkMd5: data.linkMd5,
            shortLink: modelRef.value.shortLink,
            sourceName: getSourceNameFromOptions(),
            shortLinkSelect: modelRef.value.shortLinkSelect
        }
        emit('callback', updateData)
    } else {
        message.error(error)
    }
}

function getSourceNameFromOptions() {
    for(const source of sourceOptions.value) {
        if (source.value == modelRef.value.sourceId) {
            return source.label
        }
    }

    return ''
}

const onSaveAndCopy = async () => {
    try {
        await navigator.clipboard.writeText(modelRef.value.shortLinkSelect ? modelRef.value.shortLink : modelRef.value.trackingLink)
        onSave();
    } catch (error) {
        message.success('Failed!', 1);
    }
}

watch(open, async open => {
    if (open) {
        if (!sourceOptions.value.length) {
            let { success, data, error } = await publisherProductApi.getSourceOptionsByPublisher().catch(e => ({ success: false, error: e.message }))
            if (success) {
                let options = data.map(item => ({ label: item.name, value: item.id }))
                sourceOptions.value = options;
            } else {
                message.error(error)
            }
        }
    }
})

watchEffect(async () => {
    if (open.value) {
        loading.value = true;
        if (trackLinkId) {
            let { success, data, error } = await publisherProductApi.getTrackDetailByTrackLindId(trackLinkId).catch(e => ({ success: false, error: e.message }))
            if (success) {
                modelRef.value = data
            } else {
                message.error(error)
            }
        } else {
            let { success, data, error } = await publisherProductApi.getTrackDetailByProductId({ productId, countryCode }).catch(e => ({ success: false, error: e.message }))
            if (success) {
                modelRef.value = data
            } else {
                message.error(error)
            }
        }
        loading.value = false;
    } else {
        activeKey.value = [];
    }
})

</script>

<style scoped lang="less">
.link-tool {
    .title {
        display: block;
    }

    :deep(.ant-modal-body) {
        padding: 24px;
    }
    .link-scroll-area {
        cursor: not-allowed;
        user-select: none;
        min-height: 80px;
        border-radius: 8px;
        padding: 8px 12px;
        box-sizing: border-box;
        border: 1px solid #CCC7D9;
        background-color: #EEEDF2;
        box-shadow: 0 1px 0 0 var(--box-shadow-2);
    }

    .link-textarea {
        height: 102px;
        overflow-y: auto;
        line-height: 18px;
        word-break: break-word;

        button {
            position: absolute;
            right: 10px;
            top: 10px
        }
    }

    .link-short-link {
        margin: 8px 0 16px;
        color: var(--dark-2);
        text-align: right;
        :deep(.ant) {
            &-checkbox +span {
                font-weight: normal;
                color: var(--dark-2);
                font-size: 14px;
            }
        }
    }

    .link-collapse {

        :deep([aria-expanded="true"]) {
            .icon {
                transform: rotate(180deg);
            }
        }

        .link-collapse-header {
            display: flex;
            align-items: center;
            height: 20px;

            > span {
                font-weight: 500;
                color: var(--dark-2);
            }

            .icon {
                font-size: 14px;
                margin-left: 8px;
                color: var(--dark-2);
            }
        }

        :deep(.ant-collapse-header) {
            padding: 0;
        }

        :deep(.ant-collapse-content) {
            margin-top: 6px;
            background-color: #F8F7FC;
            border-radius: 8px
        }
    }

    .link-footer {
        margin-top: 26px;
        display: flex;
        width: 100%;
        padding-top: 12px;
        gap: 8px;
        flex-direction: row-reverse;
        position: relative;

        &::after {
            content: '';
            display: block;
            height: 1px;
            background: var(--border-4);
            position: absolute;
            left: -24px;
            top: 0px;
            width: calc(100% + 48px);
        }

        .save_copy,
        .save {
            svg {
                margin-right: 8px;
                font-size: var(--font-size-large)
            }
        }

        .save {
            color: var(--primary-text);
            background-color: var(--upload-bg);
        }
        // .save_copy {
        //     color: var(--dark-1);
        //     background: var(--yellow-1);
        // }
    }

    .source {
        .sub-title {
            display: flex;
            align-items: center;
            color: var(--dark-2);
            font-weight: 500;
            font-size: 14px;

            .icon {
                font-size: 14px;
                margin-left: 5px;
                color: var(--dark-4);
            }

            :deep(.ant-tooltip-inner) {
                font-size: 13px;
                padding: 6px 12px !important;
                line-height: 18px;
                min-width: 300px !important;
            }
        }

        .option-item {
            margin-top: 8px;

            .tracking-tag {
                display: flex;
                flex-direction: column;
                gap: 4px;

                label {
                    display: inline-flex;
                    align-items: center;
                    color: var(--dark-2);
                    font-weight: 400;
                    font-size: 14px;
                    height: 20px;
                }

                a {
                    margin-left: 24px;
                    text-decoration: underline;
                    color: var(--dark-3);
                    font-size: 12px;
                    font-weight: 400;
                    display: block;
                    padding-bottom: 8px;
                }
            }

            .track-tag-input {
                display: block;
                margin: 6px 0 0 24px;
                width: 251px;
                height: 32px;
                padding: 0 11px;
                position: relative;
                background-color: var(--color-white);
                border: 1px solid #d9d9d9;
                transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
                box-sizing: border-box;
                font-size: 14px;
                line-height: 1.5714285714285714;
                list-style: none;
                display: flex;
                border-radius: 8px;
                color: var(--dark-1);

                &:focus {
                    outline: none;
                    border: 1px solid var(--primary-border-2) !important;
                    box-shadow: var(--section-box-shadow-4) !important;
                }
            }

            .source-select {
                display: block;
                width: 275px;
            }

            .add-source {
                margin: 6px 0 0 24px;
            }
        }

    }


    .option-item {
        :deep(.ant-select) {
            &-disabled {
                .ant-select-selector {
                    background: var(--bg-gray-2);
                    box-shadow: var(--section-box-shadow-2);
                    border: 1px solid var(--border-1) !important;
                    .ant-select-selection-item {
                        color: var(--dark-4);
                    }
                }
                &:hover .ant-select-selector {
                    border-color: var(--border-1);
                }
            }
            &-item {
                margin: 0 4px;
                font-weight: 400;
                color: var(--dark-1);
                &:hover {
                    background: var(--bg-gray-6);
                }
                &:active {
                    background: var(--bg-gray-4);
                }
                &-option-selected {
                    position: relative;
                    font-weight: normal;
                    background-color: var(--select-bg-1);
                    &::after {
                        top: 8px;
                        right: 8px;
                        width: 16px;
                        height: 16px;
                        content: '';
                        position: absolute;
                        background: url(../../../../assets/images/svg/select_gou.png);
                        background-size: 100%;
                    }
                }
            }
        }
    }

}

.source-edit {
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    color: var(--dark-2);
    background-color: var(--bg-gray-1);
    width: 260px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed var(--border-1);
}
</style>